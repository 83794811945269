import {
  COHORT_SESSION_BUFFER_MS,
  IANAtzName,
  MINUTE_MS,
  Millisecond,
  getDateInfo,
} from "@utils/dateTime";
import {
  CalendarEventCohortInstanceInfo,
  CalendarEventInfo,
  CalendarEventInfoPositioned,
} from "components/weekCalendar";
import { MINIMUM_DISPLAYED_LENGTH_MIN } from "components/weekCalendar/constants";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";

const STACK_OVERLAP_MINIMUM_MS = 5 * MINUTE_MS;
const COLUMN_OVERLAP_MINIMUM_MS = 5 * MINUTE_MS;
const COLUMN_WIDTH_EXPANSION = 1.7;

type Stack = {
  events: CalendarEventInfo[];
  startDateTime: Date;
  endDateTime: Date;
};

type ColumnInfo = {
  event: CalendarEventInfo;
  start: number; // base-0 index (unlike CSS grid)
  span: number;
  colLength: number;
};

/**
 * This function takes in a list of CalendarEventInfo objects and positions them
 * for display on the body of the calendar.
 * It does this by first creating "stacks" of events; events that overlap in some
 * way (determined by a "minimum overlap" constant), and therefore must be displayed
 * on top of each other.
 * Then, it creates "columns" within each stack, where events that overlap are
 * displayed side-by-side.
 * Finally, it calculates the "start" and "span" properties of each event based on
 * the columns they are in and if there is any free space available in the columns
 * to their right.
 * With the start and span determined, the function calculates the left and width
 * "position" properties of each event, as well as their z-index.
 * Steps were taken to emulate the style used by Google Calendar, including a width
 * rule that expands the width of all events in a column by 1.7x times the fraction
 * of the column they occupy (except for the last column).
 */
export const positionEvents = (
  eventInfos: CalendarEventInfo[]
): CalendarEventInfoPositioned[] => {
  const sortedEvents = eventInfos
    .filter(({ show }) => show)
    .sort((a, b) => {
      if (a.startDateTime.getTime() < b.startDateTime.getTime()) return -1;
      if (a.startDateTime.getTime() > b.startDateTime.getTime()) return 1;
      if (a.durationMinutes < b.durationMinutes) return 1;
      if (a.durationMinutes > b.durationMinutes) return -1;
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });

  const eventStacks: Stack[] = [];
  const positionedEvents: CalendarEventInfoPositioned[] = [];

  sortedEvents.forEach((event) => {
    let addedToStack = false;

    eventStacks.forEach((stack) => {
      if (isOverlap(event, stack, STACK_OVERLAP_MINIMUM_MS)) {
        stack.events.push(event);
        if (event.endDateTime.getTime() > stack.endDateTime.getTime())
          stack.endDateTime = new Date(event.endDateTime);
        addedToStack = true;
        return;
      }
    });

    if (!addedToStack) {
      eventStacks.push({
        events: [event],
        startDateTime: event.startDateTime,
        endDateTime: event.endDateTime,
      });
    }
  });

  eventStacks.forEach((stack) => {
    const columns: CalendarEventInfo[][] = [];

    stack.events.forEach((event) => {
      const columnIndex = columns.findIndex(
        (column) => !isOverlap(event, column.at(-1)!, COLUMN_OVERLAP_MINIMUM_MS)
      );

      if (columnIndex === -1) {
        columns.push([event]);
      } else {
        columns[columnIndex].push(event);
      }
    });

    const columnInfos: ColumnInfo[] = [];

    for (let start = 0; start < columns.length; ++start) {
      for (const event of columns[start]) {
        let span = 1;
        for (; start + span < columns.length; ++span) {
          if (
            columns[start + span].some((checkedEvent) =>
              isOverlap(event, checkedEvent, COLUMN_OVERLAP_MINIMUM_MS)
            )
          ) {
            break;
          }
        }
        columnInfos.push({ event, start, span, colLength: columns.length });
      }
    }

    columnInfos.forEach(({ event, start, span, colLength }) => {
      positionedEvents.push({
        ...event,
        left: (start / colLength) * 100,
        width:
          Math.min(
            (colLength - start) / colLength,
            start + 1 === colLength
              ? 1 / colLength
              : (span / colLength) * COLUMN_WIDTH_EXPANSION
          ) * 100,
        zIndex: start + 1,
      });
    });
  });

  return positionedEvents;
};

export const getDateData = (
  eventInfo: CalendarEventInfo,
  tz: IANAtzName,
  end?: boolean
) =>
  getDateInfo(
    utcToZonedTime(end ? eventInfo?.endDateTime : eventInfo?.startDateTime, tz)
  );

export const isCancellableEvent = (
  eventInstanceInfo: CalendarEventCohortInstanceInfo
) => {
  const now = new Date();
  return +eventInstanceInfo.startDateTime > +now + COHORT_SESSION_BUFFER_MS;
};

type OverlapRange = {
  startDateTime: Date;
  endDateTime: Date;
};

const isOverlap = <L extends OverlapRange, R extends OverlapRange>(
  leftRange: L,
  rightRange: R,
  overlapMinimum: Millisecond
): boolean => {
  const minimumDisplayedLengthMS = MINIMUM_DISPLAYED_LENGTH_MIN * MINUTE_MS;

  const leftStart = leftRange.startDateTime.getTime();
  const leftEnd = Math.max(
    leftStart + minimumDisplayedLengthMS,
    leftRange.endDateTime.getTime()
  );
  const leftDuration = leftEnd - leftStart;

  const rightStart = rightRange.startDateTime.getTime();
  const rightEnd = Math.max(
    rightStart + minimumDisplayedLengthMS,
    rightRange.endDateTime.getTime()
  );
  const rightDuration = rightEnd - rightStart;

  if (
    leftDuration <= minimumDisplayedLengthMS ||
    rightDuration <= minimumDisplayedLengthMS
  ) {
    return leftStart < rightEnd && rightStart < leftEnd;
  } else {
    return (
      leftStart + overlapMinimum < rightEnd &&
      rightStart + overlapMinimum < leftEnd
    );
  }
};
