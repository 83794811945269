import { Cohort } from "@generated/graphql";
import { Modal } from "components/shared";
import pluralize from "pluralize";
import { ReactNode, useState } from "react";
import { CohortsScheduleCalendarModalBody } from "./components/CohortsScheduleCalendarModalBody";

type Props = {
  show: boolean;
  cohortIds: Cohort["id"][];
  altTitle?: ReactNode;
  closeModal: () => void;
  afterLeave?: () => void;
};

export const CohortsScheduleCalendarModal = ({
  show,
  cohortIds,
  altTitle,
  afterLeave,
  closeModal,
}: Props) => {
  const [subtitle, setSubtitle] = useState<ReactNode>(altTitle);

  return (
    <Modal
      show={show}
      onClose={() => closeModal()}
      onDismissClick={() => closeModal()}
      icon={<Modal.Icon icon="calendar" />}
      title={pluralize("Cohort Schedule", cohortIds.length)}
      subtitle={subtitle}
      width="full"
      afterLeave={afterLeave}
    >
      <CohortsScheduleCalendarModalBody
        cohortIds={cohortIds}
        setSubtitle={altTitle ? undefined : setSubtitle}
      />
    </Modal>
  );
};
