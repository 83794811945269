import { IANAtzName, LocalizedWeekday, WeekdayNumber } from "@utils/dateTime";
import { CalendarEventInfo } from "components/weekCalendar";
import { useMemo } from "react";
import { CalendarEvent } from "./components/CalendarEvent/CalendarEvent";
import { positionEvents } from "./utils";

type Props = {
  calendarEventClassName?: string;
  localizedWeekdays: LocalizedWeekday[];
  focusedWeekday: WeekdayNumber;
  eventInfos: CalendarEventInfo[];
  locale: string;
  viewingTimeZone: IANAtzName;
  mode24Hour: boolean;
  refetch?: () => void;
};

export const CalendarEvents = ({
  calendarEventClassName,
  localizedWeekdays,
  focusedWeekday,
  eventInfos,
  locale,
  viewingTimeZone,
  mode24Hour,
  refetch,
}: Props) => {
  const positionedEventInfos = useMemo(
    () => positionEvents(eventInfos),
    [eventInfos]
  );

  return positionedEventInfos.map((posEventInfo) => (
    <CalendarEvent
      key={posEventInfo.key}
      className={calendarEventClassName}
      eventInfo={posEventInfo}
      localizedWeekdays={localizedWeekdays}
      focusedWeekday={focusedWeekday}
      locale={locale}
      mode24Hour={mode24Hour}
      viewingTimeZone={viewingTimeZone}
      refetch={refetch}
    />
  ));
};
