import { gql } from "@apollo/client";
import { FilterCount } from "@contexts/AdminDashboardProvider";
import {
  AccountStatus,
  BuildCalendarEventCohortInstanceInfo_CohortEventInstanceFragment,
  BuildCalendarEventCohortInstanceInfo_CohortFragment,
  BuildCalendarEventEngagementAggregateInfo_EngagementAggregateCalendarEventFragment,
  BuildCalendarNavData_HolidayFragment,
  Cohort,
  CohortEventInstanceStatus,
  Organization,
  RosterRecordStatus,
  User,
} from "@generated/graphql";
import {
  DAY_MS,
  floatingToET,
  floatingToZonedMS,
  getISODatesInRange,
  getLocalizedWeekdays,
  normalizeToUtcISODate,
} from "@utils/dateTime";
import { IndexMap } from "@utils/indexMap";
import { getCohortSubjectText, getGradeLevelText } from "@utils/labels";
import { makeCacheKey } from "@utils/strings";
import { add, formatISO } from "date-fns";
import { sortBy } from "lodash";
import compact from "lodash/compact";
import { filterCohortEventInstancesOnEngagementShifts } from "sections/EngagementsDashboard/components/PaginatedCohortsPanelContainer/CohortEventsContainer/components/EngagementCohortsPanel/components/CohortEventList/helpers";
import { DEFAULT_EVENT_COLOR, EVENT_COLORS } from ".";
import { CalendarEventCohortDetails } from "./components/CalendarEventCohortDetails";
import {
  CalendarEventCohortInstanceInfo,
  CalendarEventEngagementAggregateInfo,
  CalendarEventType,
  CalendarNavData,
  ContentProps,
  EventColor,
} from "./types";
import { getCharmIcon } from "./utils";

buildCalendarEventCohortInstanceInfo.fragments = {
  cohort: gql`
    fragment BuildCalendarEventCohortInstanceInfo_Cohort on Cohort {
      id
      name
      endDate
      startDate
      instructionLevel
      engagement {
        id
        name
        engagementShifts {
          ...FilterCohortEventInstancesOnEngagementShifts_EngagementShift
        }
        rosterRecords {
          startDate
          endDate
          teacherId
          status
        }
      }
      staffAssignments {
        cohortSubject
        cohortAssignmentRole
        user {
          id
          fullName
          accountRole
          accountStatus
        }
      }
      ...CalendarEventCohortDetails_Cohort
    }
    ${filterCohortEventInstancesOnEngagementShifts.fragments.engagementShift}
    ${CalendarEventCohortDetails.fragments.cohort}
  `,
  cohortEventInstance: gql`
    fragment BuildCalendarEventCohortInstanceInfo_CohortEventInstance on CohortEventInstance {
      startDateTime
      startFloatingDateTime
      timeZone
      durationMinutes
      cohortSubject
      cohortSubSubject
      cohortId
      cohortEventId
      cohortSessionId
      teacherAssignedId
      status
    }
  `,
};

/**
 * To save on bandwidth and DB hits we split up data fetching into two halves.
 * First is an array of Cohorts with tons of information but no eventInstances.
 * The second an array of Cohorts (the same Cohorts) but with just the eventInstances.
 * At the time of writing the cohortsWithoutEvents fetch might be in the
 * neighborhood of 1.0-1.3 kB per Cohort.
 * While the cohortEvents is about 200B per event.
 * So, if a schedule can be expected to have 5 events a week, that is about 1kB
 * per click instead of 2.0-2.3kB per click, and the server doesn't need to
 * repeatedly look up relationships between Cohorts, Engagements,
 * StaffAssignments, Users, etc. Instead it'll just get the Events, parsing
 * rRules all the while.
 * @param cohortsWithoutEventInstances
 * @param cohortEventInstances
 * @param targetUserIds
 * @param showInactiveStaff
 * @returns CalendarEventCohortInstanceInfo[]
 */
export function buildCalendarEventCohortInstanceInfo(
  cohortsWithoutEventInstances: BuildCalendarEventCohortInstanceInfo_CohortFragment[],
  cohortEventInstances: BuildCalendarEventCohortInstanceInfo_CohortEventInstanceFragment[],
  targetUserIds: User["id"][],
  showInactiveStaff: boolean
): CalendarEventCohortInstanceInfo[] {
  const filterEventsByDates = (
    {
      startFloatingDateTime,
      timeZone,
    }: BuildCalendarEventCohortInstanceInfo_CohortEventInstanceFragment,
    cohort: BuildCalendarEventCohortInstanceInfo_CohortFragment
  ) => {
    const eventStart = floatingToZonedMS(
      new Date(startFloatingDateTime),
      timeZone
    ).getTime();
    const cohortStart = floatingToET(new Date(cohort.startDate)).getTime();
    const cohortEnd = floatingToET(new Date(cohort.endDate + DAY_MS)).getTime();

    if (!(eventStart >= cohortStart && eventStart < cohortEnd)) return false;

    const rosterRecord = cohort.engagement.rosterRecords.find(
      ({ teacherId, status }) =>
        targetUserIds.includes(teacherId) &&
        status === RosterRecordStatus.Confirmed
    );

    if (!rosterRecord) return true;

    const rosterStart = new Date(rosterRecord.startDate).getTime();
    const rosterEnd = new Date(rosterRecord.endDate + DAY_MS).getTime();

    return eventStart >= rosterStart && eventStart <= rosterEnd;
  };

  let groupIndex = 0;
  const groupMap: Record<string, number> = {};

  const eventInfos: CalendarEventCohortInstanceInfo[] =
    cohortsWithoutEventInstances.flatMap((cohortWithoutEventInstances) => {
      const matchingCohortEventInstances = cohortEventInstances.filter(
        ({ cohortId }) => cohortId === cohortWithoutEventInstances.id
      );

      if (!matchingCohortEventInstances) return [];

      const cohortIdsMappedToShiftAssignmentTimes =
        filterCohortEventInstancesOnEngagementShifts(
          matchingCohortEventInstances,
          cohortWithoutEventInstances.engagement.engagementShifts,
          cohortWithoutEventInstances.engagement.id,
          targetUserIds
        ).reduce<Record<Cohort["id"], number[]>>(
          (acc, { cohortId, startDateTime }) => {
            const start = new Date(startDateTime);
            if (acc[cohortId]) {
              acc[cohortId].push(+start);
            } else {
              acc[cohortId] = [+start];
            }
            return acc;
          },
          {}
        );

      return compact(
        matchingCohortEventInstances
          .filter((eventInstance) =>
            filterEventsByDates(eventInstance, cohortWithoutEventInstances)
          )
          .map((eventInstance) => {
            const subjectStaff = cohortWithoutEventInstances.staffAssignments
              .filter(
                (staffAssignment) =>
                  staffAssignment.cohortSubject ===
                    eventInstance.cohortSubject &&
                  (!eventInstance.teacherAssignedId ||
                    eventInstance.teacherAssignedId ===
                      staffAssignment.user.id) &&
                  (showInactiveStaff ||
                    staffAssignment.user.accountStatus !==
                      AccountStatus.Inactive)
              )
              .sort((a, b) => {
                if (a.user.accountRole === b.user.accountRole) {
                  // Place Substitute teachers below Primary teachers.
                  if (a.cohortAssignmentRole !== b.cohortAssignmentRole) {
                    return a.cohortAssignmentRole < b.cohortAssignmentRole
                      ? -1
                      : 1;
                  }

                  // Sort by full name alphabetic if accountRole is identical.
                  return a.user.fullName < b.user.fullName ? -1 : 1;
                }
                // Sort by accountRole reverse alphabetic (Tutor before Mentor).
                return a.user.accountRole < b.user.accountRole ? 1 : -1;
              });

            const targetSubjectStaff = subjectStaff.filter(({ user }) =>
              targetUserIds.includes(user.id)
            );

            // If there are targetUserIds and there are no targetedSubjectStaff,
            // then we need to check if the event is on an Engagement shift.
            // If there is targetedSubjectStaff, then we don't need to check if
            // the event is on an engagement shift (it's not relevant), so we
            // can just set onEngagementShift to undefined.
            const onEngagementShift =
              targetUserIds.length !== 0 && targetSubjectStaff.length === 0
                ? cohortIdsMappedToShiftAssignmentTimes[
                    eventInstance.cohortId
                  ]?.includes(+new Date(eventInstance.startDateTime)) ?? false
                : undefined;

            // Here we are checking if the eventInstance has a teacherAssignedId,
            // and if it does, and if the targetSubjectStaff is not empty, then
            // we must check if the teacherAssignedId matches any of the target
            // user IDs. If it does not, then we can return undefined and remove
            // the eventInstance from the list.
            if (
              targetSubjectStaff.length !== 0 &&
              targetSubjectStaff.every(
                ({ user }) =>
                  eventInstance.teacherAssignedId &&
                  user.id !== eventInstance.teacherAssignedId
              )
            ) {
              return undefined;
            }

            const groupKey = makeCacheKey(
              cohortWithoutEventInstances.id,
              eventInstance.cohortSubject
            );
            if (groupMap[groupKey] === undefined) {
              groupMap[groupKey] = groupIndex;
              ++groupIndex;
            }
            const eventColor =
              eventInstance.status === CohortEventInstanceStatus.Cancelled
                ? DEFAULT_EVENT_COLOR
                : EVENT_COLORS[groupMap[groupKey] % EVENT_COLORS.length];

            return {
              type: CalendarEventType.CohortEventInstance,
              startDateTime: new Date(eventInstance.startDateTime),
              isoStartFloatingDateTime: new Date(
                eventInstance.startFloatingDateTime
              ).toISOString(),
              endDateTime: add(new Date(eventInstance.startDateTime), {
                minutes: eventInstance.durationMinutes,
              }),
              durationMinutes: eventInstance.durationMinutes,
              timeZone: eventInstance.timeZone,
              status: eventInstance.status,
              cohortStartDate: new Date(cohortWithoutEventInstances.startDate),
              cohortEndDate: new Date(cohortWithoutEventInstances.endDate),
              cohortEventId: eventInstance.cohortEventId ?? null,
              cohortSessionId: eventInstance.cohortSessionId ?? null,
              cohortId: eventInstance.cohortId,
              cohortName: cohortWithoutEventInstances.name,
              onEngagementShift,
              key: makeCacheKey(
                eventInstance.cohortEventId ??
                  eventInstance.cohortSessionId ??
                  "",
                new Date(eventInstance.startDateTime).getTime()
              ),
              title: `${cohortWithoutEventInstances.name} @ ${cohortWithoutEventInstances.engagement.name}`,
              details: `${getCohortSubjectText(
                eventInstance.cohortSubject
              )}(${getGradeLevelText(
                cohortWithoutEventInstances.instructionLevel
              )})`,
              content: ({ eventColor }: ContentProps) => (
                <CalendarEventCohortDetails
                  cohort={cohortWithoutEventInstances}
                  filteredStaffAssignments={subjectStaff}
                  eventColor={eventColor}
                />
              ),
              charmIcon: getCharmIcon(eventInstance.cohortSubject),
              eventColor,
              show: true,
            };
          })
      );
    });

  return eventInfos;
}

buildCalendarNavData.fragments = {
  holiday: gql`
    fragment BuildCalendarNavData_Holiday on Holiday {
      id
      type
      name
      startDate
      endDate
      organization {
        id
        name
      }
    }
  `,
};

export function buildCalendarNavData(
  targetDateTime: Date,
  locale: string,
  eventInfos: CalendarEventCohortInstanceInfo[],
  holidays: BuildCalendarNavData_HolidayFragment[]
): CalendarNavData[] {
  const localizedWeekdays = getLocalizedWeekdays(
    formatISO(targetDateTime, { representation: "date" }),
    locale
  );

  const holidaysForWeek = holidays.filter(
    ({ startDate, endDate }) =>
      normalizeToUtcISODate(new Date(startDate)) <=
        localizedWeekdays[6].isoDate &&
      normalizeToUtcISODate(new Date(endDate)) >= localizedWeekdays[0].isoDate
  );

  return localizedWeekdays.map((localizedWeekday) => {
    const holidaysForDay = holidaysForWeek.filter(
      ({ startDate, endDate }) =>
        normalizeToUtcISODate(new Date(startDate)) <=
          localizedWeekday.isoDate &&
        normalizeToUtcISODate(new Date(endDate)) >= localizedWeekday.isoDate
    );

    return {
      localizedWeekday,
      eventInfos: eventInfos.filter(
        ({ startDateTime }) =>
          formatISO(startDateTime, { representation: "date" }) ===
          localizedWeekday.isoDate
      ),
      navHolidays: sortBy(
        holidaysForDay.map((holiday) => {
          const isoDatesRange = getISODatesInRange(
            holiday.startDate,
            holiday.endDate
          );
          return {
            id: holiday.id,
            type: holiday.type,
            name: holiday.name,
            startDate: holiday.startDate,
            endDate: holiday.endDate,
            dayNumber:
              isoDatesRange.length === 1
                ? 1
                : isoDatesRange.indexOf(localizedWeekday.isoDate) + 1,
            daysCount: isoDatesRange.length,
            organizationName: holiday.organization?.name,
          };
        }),
        "startDate"
      ),
    };
  });
}

export function buildBareCalendarNavData(
  targetDateTime: Date,
  locale: string
): CalendarNavData[] {
  const localizedWeekdays = getLocalizedWeekdays(
    formatISO(targetDateTime, { representation: "date" }),
    locale
  );

  return localizedWeekdays.map((localizedWeekday) => ({
    localizedWeekday,
    eventInfos: [],
    navHolidays: [],
  }));
}

buildCalendarEventEngagementAggregateInfo.fragments = {
  engagementAggregateCalendarEvent: gql`
    fragment BuildCalendarEventEngagementAggregateInfo_EngagementAggregateCalendarEvent on EngagementAggregateCalendarEvent {
      startFloatingDateTime
      timeZone
      startDateTime
      durationMinutes
      organizationId
      engagementId
      organizationName
      engagementName
      cohortEventInstanceCount
      engagementStartDate
      engagementEndDate
      cohortEventInstances {
        startFloatingDateTime
        timeZone
        startDateTime
        durationMinutes
        cohortId
        cohortSubject
        cohortSubSubject
        status
      }
      cohorts {
        id
        name
      }
    }
  `,
};

export function buildCalendarEventEngagementAggregateInfo(
  engagementAggregateCalendarEvents: BuildCalendarEventEngagementAggregateInfo_EngagementAggregateCalendarEventFragment[],
  filterCallback: (
    event: BuildCalendarEventEngagementAggregateInfo_EngagementAggregateCalendarEventFragment
  ) => boolean,
  filterCountCallback: (filterCount: FilterCount) => void,
  eventColorCallback: (
    eventColorMap: Record<Organization["id"], EventColor>
  ) => void
): CalendarEventEngagementAggregateInfo[] {
  let filterCountShown = 0;
  let filterCountTotal = 0;
  let groupIndex = 0;
  const eventColorMap = engagementAggregateCalendarEvents.reduce<
    Record<string, EventColor>
  >((acc, { organizationId }) => {
    if (acc[organizationId] === undefined) {
      acc[organizationId] = EVENT_COLORS[groupIndex % EVENT_COLORS.length];
      ++groupIndex;
    }
    return acc;
  }, {});

  const engagementAggregateEventInfos: CalendarEventEngagementAggregateInfo[] =
    engagementAggregateCalendarEvents.map((aggregate) => {
      const cohortsMap = new IndexMap(aggregate.cohorts, ({ id }) => id);
      const orgNameInEngName = aggregate.engagementName
        .toLowerCase()
        .startsWith(aggregate.organizationName.toLowerCase());

      const show = filterCallback(aggregate);
      if (show) ++filterCountShown;
      ++filterCountTotal;

      return {
        type: CalendarEventType.EngagementEventAggregate,

        isoStartFloatingDateTime: new Date(
          aggregate.startFloatingDateTime
        ).toISOString(),
        startDateTime: new Date(aggregate.startDateTime),
        timeZone: aggregate.timeZone,
        durationMinutes: aggregate.durationMinutes,
        endDateTime: add(new Date(aggregate.startDateTime), {
          minutes: aggregate.durationMinutes,
        }),

        key: makeCacheKey(
          aggregate.engagementId,
          new Date(aggregate.startDateTime).getTime(),
          aggregate.durationMinutes
        ),
        title: `${orgNameInEngName ? "" : `${aggregate.organizationName} — `}${
          aggregate.engagementName
        } (${aggregate.cohortEventInstanceCount})`,

        engagementId: aggregate.engagementId,
        engagementStartDate: new Date(aggregate.engagementStartDate),
        engagementEndDate: new Date(aggregate.engagementEndDate),
        events: aggregate.cohortEventInstances
          .map(({ cohortId, cohortSubject, cohortSubSubject, status }) => ({
            cohortId,
            cohortName: cohortsMap.get(cohortId)?.name ?? "",
            cohortSubject,
            cohortSubSubject,
            status,
          }))
          .sort((a, b) => a.cohortName.localeCompare(b.cohortName)),
        eventColor: eventColorMap[aggregate.organizationId],
        show,
      };
    });

  eventColorCallback(eventColorMap);
  filterCountCallback({ shown: filterCountShown, total: filterCountTotal });

  return engagementAggregateEventInfos;
}
