import { gql, useQuery } from "@apollo/client";
import {
  CohortEventInstanceStatus,
  CohortsScheduleCalendar_CohortFragment,
  CohortsScheduleCalendarGetCohortsHolidaysQuery,
  CohortsScheduleCalendarGetCohortsHolidaysQueryVariables,
  CohortsScheduleCalendarGetEventInstancesQuery,
  CohortsScheduleCalendarGetEventInstancesQueryVariables,
  User,
} from "@generated/graphql";
import { LocalizedWeekday } from "@utils/dateTime";
import { fetchErrToast } from "@utils/errorLogging";
import {
  buildCalendarEventCohortInstanceInfo,
  buildCalendarNavData,
  CalendarNavData,
  WeekCalendar,
  WeekCalendarHeader,
} from "components/weekCalendar";
import { useAuth } from "contexts/AuthProvider";
import { endOfWeek, startOfWeek } from "date-fns";
import { ReactNode, useEffect, useMemo, useState } from "react";

CohortsScheduleCalendar.fragments = {
  cohort: gql`
    fragment CohortsScheduleCalendar_Cohort on Cohort {
      id
      ...BuildCalendarEventCohortInstanceInfo_Cohort
    }
    ${buildCalendarEventCohortInstanceInfo.fragments.cohort}
  `,
};

const GET_EVENT_INSTANCES_QUERY = gql`
  query CohortsScheduleCalendarGetEventInstances(
    $cohortIds: [ID!]!
    $dateTimeRange: DateTimeRangeInput!
    $includeExDates: Boolean!
  ) {
    cohortEventInstances(
      cohortIds: $cohortIds
      dateTimeRange: $dateTimeRange
      includeExDates: $includeExDates
    ) {
      ...BuildCalendarEventCohortInstanceInfo_CohortEventInstance
    }
  }
  ${buildCalendarEventCohortInstanceInfo.fragments.cohortEventInstance}
`;

const GET_COHORTS_HOLIDAYS_QUERY = gql`
  query CohortsScheduleCalendarGetCohortsHolidays($cohortIds: [ID!]!) {
    getCohortsHolidays(cohortIds: $cohortIds) {
      id
      ...WeekCalendar_Holiday
      ...WeekCalendarHeader_Holiday
      ...BuildCalendarNavData_Holiday
    }
  }
  ${WeekCalendar.fragments.holiday}
  ${WeekCalendarHeader.fragments.holiday}
  ${buildCalendarNavData.fragments.holiday}
`;

type Props = {
  endBound?: Date;
  startBound?: Date;
  showInactiveStaff?: boolean;
  targetUserIds?: User["id"][];
  externalControls?: ReactNode;
  exportCalendarButton?: ReactNode;
  cohortsWithoutEvents: CohortsScheduleCalendar_CohortFragment[];
  onTargetDateTimeChange?: (targetDateTime: Date) => void;
};

export function CohortsScheduleCalendar({
  endBound,
  startBound,
  externalControls,
  targetUserIds = [],
  cohortsWithoutEvents,
  showInactiveStaff = false,
  onTargetDateTimeChange,
}: Props) {
  const { isAdmin } = useAuth();
  const cohortIds = cohortsWithoutEvents.map(({ id }) => id);
  const viewingTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const todayNoonDateTime = new Date();
  todayNoonDateTime.setHours(12, 0, 0);
  const [viewDate, setViewDate] = useState(todayNoonDateTime);

  useEffect(() => {
    onTargetDateTimeChange?.(viewDate);
  }, [viewDate, onTargetDateTimeChange]);

  const dateTimeRange = useMemo(() => {
    const start = +startOfWeek(viewDate);
    const end = +endOfWeek(viewDate);
    return { start, end };
  }, [viewDate]);

  const {
    data: eventsData,
    refetch,
    loading,
  } = useQuery<
    CohortsScheduleCalendarGetEventInstancesQuery,
    CohortsScheduleCalendarGetEventInstancesQueryVariables
  >(GET_EVENT_INSTANCES_QUERY, {
    fetchPolicy: "cache-first",
    variables: {
      cohortIds,
      dateTimeRange,
      includeExDates: isAdmin,
    },
    onError: (error) => fetchErrToast("calendar events.", error),
  });

  const { data: holidaysData } = useQuery<
    CohortsScheduleCalendarGetCohortsHolidaysQuery,
    CohortsScheduleCalendarGetCohortsHolidaysQueryVariables
  >(GET_COHORTS_HOLIDAYS_QUERY, {
    variables: { cohortIds },
    fetchPolicy: "cache-first",
    onError: (error) => fetchErrToast("calendar holidays.", error),
  });

  const { eventInstanceInfos, eventInstanceInfosForDisplay } = useMemo(() => {
    const eventInstanceInfos = buildCalendarEventCohortInstanceInfo(
      cohortsWithoutEvents,
      eventsData?.cohortEventInstances ?? [],
      targetUserIds,
      showInactiveStaff
    );
    const eventInstanceInfosForDisplay = eventInstanceInfos.filter(
      ({ status, onEngagementShift }) =>
        status !== CohortEventInstanceStatus.Cancelled &&
        onEngagementShift !== false
    );

    return {
      eventInstanceInfos,
      eventInstanceInfosForDisplay,
    };
  }, [
    targetUserIds,
    showInactiveStaff,
    cohortsWithoutEvents,
    eventsData?.cohortEventInstances,
  ]);

  const { navData, localizedWeekdays } = useMemo<{
    navData: CalendarNavData[];
    localizedWeekdays: LocalizedWeekday[];
  }>(() => {
    const navData = buildCalendarNavData(
      viewDate,
      Intl.NumberFormat().resolvedOptions().locale,
      eventInstanceInfos,
      holidaysData?.getCohortsHolidays ?? []
    );
    const localizedWeekdays = navData.map(
      ({ localizedWeekday }) => localizedWeekday
    );

    return { navData, localizedWeekdays };
  }, [viewDate, eventInstanceInfos, holidaysData?.getCohortsHolidays]);

  return (
    <div className="relative flex h-full flex-col w-full">
      <WeekCalendarHeader
        viewDate={viewDate}
        endBound={endBound}
        cohortIds={cohortIds}
        startBound={startBound}
        externalControls={externalControls}
        localizedWeekdays={localizedWeekdays}
        holidays={holidaysData?.getCohortsHolidays ?? []}
        eventInstanceInfos={eventInstanceInfosForDisplay}
        setViewDate={setViewDate}
      />

      <WeekCalendar
        refetch={refetch}
        navData={navData}
        mode24Hour={false}
        viewDate={viewDate}
        viewingTimeZone={viewingTimeZone}
        localizedWeekdays={localizedWeekdays}
        eventInfosForDisplay={eventInstanceInfosForDisplay}
        locale={Intl.NumberFormat().resolvedOptions().locale}
        setViewDate={(date: Date) => setViewDate(date)}
        loading={loading}
      />
    </div>
  );
}
