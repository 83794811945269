import { gql, useQuery } from "@apollo/client";
import {
  Cohort,
  CohortsScheduleCalendarModalQuery,
  CohortsScheduleCalendarModalQueryVariables,
} from "@generated/graphql";
import { getGradeLevelText } from "@utils/labels";
import { ErrorBox, Spinner } from "components/shared";
import { ReactNode, useEffect } from "react";
import { CohortsScheduleCalendar } from "../../CohortsScheduleCalendar";

CohortsScheduleCalendarModalBody.fragments = {
  cohort: gql`
    fragment CohortsScheduleCalendarModal_Cohort on Cohort {
      name
      instructionLevel
      engagement {
        name
        endDate
        startDate
      }
      ...CohortsScheduleCalendar_Cohort
    }
    ${CohortsScheduleCalendar.fragments.cohort}
  `,
};

const COHORTS_SCHEDULE_CALENDAR_MODAL_QUERY = gql`
  query CohortsScheduleCalendarModal($cohortIds: [ID!]!) {
    cohorts(ids: $cohortIds) {
      ...CohortsScheduleCalendarModal_Cohort
    }
  }
  ${CohortsScheduleCalendarModalBody.fragments.cohort}
`;

type Props = {
  cohortIds: Cohort["id"][];
  setSubtitle?: (subtitle: ReactNode) => void;
};

export function CohortsScheduleCalendarModalBody({
  cohortIds,
  setSubtitle,
}: Props) {
  const { data, loading, error } = useQuery<
    CohortsScheduleCalendarModalQuery,
    CohortsScheduleCalendarModalQueryVariables
  >(COHORTS_SCHEDULE_CALENDAR_MODAL_QUERY, {
    variables: { cohortIds },
  });

  useEffect(() => {
    if (setSubtitle && data?.cohorts) {
      setSubtitle(
        data.cohorts
          .map(
            ({ name, engagement, instructionLevel }) =>
              `${name} @ ${engagement.name}(${getGradeLevelText(
                instructionLevel
              )})`
          )
          .join(", ")
      );
    }
  }, [data?.cohorts, setSubtitle]);

  return (
    <div className="relative mt-3">
      <ErrorBox msg={error?.message} />
      {loading ? (
        <Spinner size={10} />
      ) : (
        data?.cohorts && (
          <div className="h-[calc(100vh-220px)]">
            <CohortsScheduleCalendar
              cohortsWithoutEvents={data.cohorts}
              endBound={data.cohorts?.[0]?.engagement?.endDate}
              startBound={data.cohorts?.[0]?.engagement?.startDate}
            />
          </div>
        )
      )}
    </div>
  );
}
